<template>
  <b-card bg-variant="Default">
    
    <!-- table -->
    <vue-good-table
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    :totalRows="totalRecords"
    :rows="rows"
    :columns="columns"
    :isLoading.sync="isLoading"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    :pagination-options="{
        enabled: true,
        mode: 'pages',
        nextLabel: 'successiva',
        prevLabel: 'precedente',
        rowsPerPageLabel: 'righe per pagina',
        ofLabel: 'di',
        pageLabel: 'pagina', // for 'pages' mode
        perPage: 10
    }"
    styleClass="vgt-table">

      <template slot="loadingContent_stop">
        <div class="bg-primary"> animated loading ....</div>
      </template>

      <div slot="emptystate"> <div class="text-center"> Nessun dato disponibile </div> </div>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Ordine -->
        <div
          v-if="props.column.field === 'numero_ordine'"
         >
          <span class="font-small-2 text-muted">
            {{ props.row.data_inserimento }}
          </span>
        </div>

        <div
          v-if="props.column.field === 'subtotale'"
         >
          {{ props.row.subtotale }} ({{ props.row.iva }})
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <div class="text-nowrap">
            <feather-icon
                :id="`tabellaordini-riga-${props.row.id}-riepilogo`"
                icon="InfoIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="$router.push({ name: 'crm-ordine-info', params: { id: props.row.id, numero_ordine: props.row.numero_ordine }})"
            />
            <b-tooltip
                title="riepilogo ordine"
                class="cursor-pointer"
                :target="`tabellaordini-riga-${props.row.id}-riepilogo`"
            />
            <feather-icon
                :id="`tabellaordini-riga-${props.row.id}-lead`"
                icon="FileTextIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="$router.push({ name: 'crm-lead-dettaglio', params: { id_lead: props.row.id_lead, id_azienda: props.row.id_azienda }})"
            />
            <b-tooltip
                title="richiesta collegata"
                class="cursor-pointer"
                :target="`tabellaordini-riga-${props.row.id}-lead`"
            />
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      
    </vue-good-table>
  </b-card>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { BCard, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BButton, BDropdown, BDropdownItem, BTooltip } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    VueGoodTable,
    BButton,
    BTooltip,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      columns: [
        {
          label: 'Azienda',
          field: 'nome_azienda',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'N.Ordine',
          field: 'numero_ordine',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Subtotale (iva)',
          field: 'subtotale',
          filterOptions: {
            enabled: false,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Sconto',
          field: 'sconto',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Totale ordine',
          field: 'totalone',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Operazioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10,
        licenza_pubblica: 'testlicenza'
      },
      userData: {},
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))

    //this.rows = [];
    this.loadItems();    
  },
  methods: {
    updateParams(newProps) {
      //console.log("updateParams --- vvv ")
      console.log(newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
        this.$http.get('v2/crmadmin/listaordinicrm/'+this.userData.id, {
            params: this.serverParams 
            }).then(response => { 
                
                //console.log("risposta vvv"); 
                //console.log(response.data)
                //console.log('--- ---')
                console.dir(response.data)
                console.log("response.totalRecords -> "+response.data.totalRecords);
                //console.log(response.data.rows)
                
                this.totalRecords = response.data[0].contarighe;
                this.rows = response.data[1].righe;                
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;            
            })    
    },

  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
